const treatmentsOptions = [
  { value: "None" },
  { value: "Minoxidil" },
  { value: "Finasteride" },
  { value: "Dutasteride" },
  { value: "PRP (Platelet-Rich Plasma)" },
  { value: "LLLT" },
  { value: "RU58841" },
  { value: "Stemoxydine" },
  { value: "Pyrilutamide" },
  { value: "Ketoconazole" },
  { value: "Pumpkin Seed Oil" },
  { value: "Bimatoprost" },
  { value: "Latanoprost" },
  { value: "Azelaic Acid" },
  { value: "Adenosine" },
  { value: "Copper Peptides" },
  { value: "Saw Palmetto" },
  { value: "EGCG (Epigallocatechin Gallate" },
  { value: "Rosemary Oil" },
  { value: "Spironolactone" },
  { value: "Cetirizine" },
  { value: "Panthenol" },
  { value: "Niacin" },
  { value: "Hyaluronic Acid" },
  { value: "Melatonin" },
  { value: "Rosveratrol" },
  { value: "Zinc" },
  { value: "Riboflavin" },
  { value: "Vitamin B12" },
  { value: "Turmeric" },
  { value: "Biotin" },
  { value: "Lavender Oil" },
  { value: "Peppermint Oil" },
  { value: "Ginseng" },
  { value: "Microneedling" },
  { value: "CosmeRNA" },
];

export default treatmentsOptions;
