import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import Home from "./pages/Home";
import NorwoodScale from "./pages/NorwoodScale";
import DiffuseScale from "./pages/DiffuseScale";
import GetChecked from "./pages/GetChecked";
import Success from "./pages/Success";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import Result from "./pages/Result";
import "./App.css";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#1677ff",
        },
      }}
    >
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/norwood-scale" element={<NorwoodScale />} />
          <Route path="/diffuse-scale" element={<DiffuseScale />} />
          <Route path="/norwood-scale/result" element={<Result />} />
          <Route path="/get-checked" element={<GetChecked />} />
          <Route path="/get-checked/success" element={<Success />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ConfigProvider>
  );
}

export default App;
