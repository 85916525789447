import { Result, Button } from "antd";
import Header from "../components/shared/Header";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Success = () => {
  const navigate = useNavigate("");

  useEffect(() => {
    setTimeout(() => navigate("/"), 5000);
  });

  return (
    <div>
      <Header page={"/home"} />
      <Result
        status="success"
        title="Successfully purchased Hair Check!"
        subTitle="You will be redirected to the home page in few seconds."
        extra={[
          <Button type="primary" key="console" onClick={() => navigate("/")}>
            Go Home
          </Button>,
        ]}
      />
    </div>
  );
};

export default Success;
