import { Carousel, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import left from "../../images/left.gif";
import right from "../../images/right.gif";
import successWithoutReload from "../../images/success.gif";
import front from "../../images/front.png";
import { useState, useRef } from "react";
import Webcam from "./Webcam";
import TopWebcam from "./TopWebcam";
import "./Granted.css";

const Granted = ({ modelLoaded, setShowResult }) => {
  const showLoading = !modelLoaded;

  // Trick to make the GIF restart every time.
  const success = `${successWithoutReload}?a=${Math.random()}`;

  const [webcamLoaded, setWebcamLoaded] = useState(false);
  const [step, setStep] = useState("left");
  const [webcamWrapResized, setWebcamWrapResized] = useState(false);
  const [webcamRestart, setWebcamRestart] = useState(false);

  const webcamRef = useRef(null);
  const topWebcamRef = useRef(null);

  const isIntermediateStep = step === "leftToRight" || step === "rightToFront";

  const LoadingScreen = (
    <Spin size="large" indicator={<LoadingOutlined />} className="spin" />
  );

  var step_num, imageSRC, WebcamText;
  switch (step) {
    case "left":
      step_num = 1;
      imageSRC = left;
      WebcamText = (
        <p>
          Turn your face to the <b>left</b>
        </p>
      );
      break;
    case "leftToRight":
      step_num = 1;
      imageSRC = success;
      WebcamText = <p></p>;
      break;
    case "right":
      step_num = 2;
      imageSRC = right;
      WebcamText = (
        <p>
          Turn your face to the <b>right</b>
        </p>
      );
      break;
    case "rightToFront":
      step_num = 2;
      imageSRC = success;
      WebcamText = <p></p>;
      break;
    case "front":
      step_num = 3;
      imageSRC = front;
      WebcamText = (
        <p>
          Look <b>directly</b> to the camera
        </p>
      );
      break;
  }

  const showWebcamWrap =
    webcamLoaded && webcamWrapResized && !isIntermediateStep;

  return (
    <div className="granted-wrapper">
      {showLoading ? (
        LoadingScreen
      ) : (
        <>
          {!webcamLoaded && LoadingScreen}
          <TopWebcam
            step={step}
            setStep={setStep}
            topWebcamRef={topWebcamRef}
            webcamRef={webcamRef}
            showWebcamWrap={showWebcamWrap}
            step_num={step_num}
            setWebcamRestart={setWebcamRestart}
          />
          <Webcam
            webcamRef={webcamRef}
            setShowResult={setShowResult}
            webcamLoaded={webcamLoaded}
            setWebcamLoaded={setWebcamLoaded}
            step={step}
            isIntermediateStep={isIntermediateStep}
            setStep={setStep}
            imageSRC={imageSRC}
            topWebcamRef={topWebcamRef}
            setWebcamWrapResized={setWebcamWrapResized}
            webcamRestart={webcamRestart}
            setWebcamRestart={setWebcamRestart}
          />
          {showWebcamWrap && (
            <div className="webcam-text-wrapper">
              {WebcamText}
              <div className="carousel-wrapper">
                <Carousel autoplay={true} effect="fade" dots={false}>
                  <div>
                    <p className="carousel-text">
                      Move your head like the black silhouette
                    </p>
                  </div>
                  <div>
                    <p className="carousel-text">Show your hairline</p>
                  </div>
                  <div>
                    <p className="carousel-text">Pick a bright place</p>
                  </div>
                </Carousel>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Granted;
