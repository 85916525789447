import "./Footer.css";

const Footer = ({ backgroundColor }) => {
  return (
    <div className="about-footer" style={{ backgroundColor: backgroundColor }}>
      Contact: amibaldingco@gmail.com
    </div>
  );
};

export default Footer;
