import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { beforeUpload } from "../../utils/config";
import useStore from "../../store";
import "./TopWebcam.css";

const TopWebcam = ({
  step,
  setStep,
  topWebcamRef,
  webcamRef,
  showWebcamWrap,
  step_num,
  setWebcamRestart,
}) => {
  const { addImage } = useStore();

  function encodeImageFileAsURL(file) {
    let reader = new FileReader();
    reader.onloadend = () => {
      const data = reader.result;
      if (step === "left") {
        addImage("left", data);
        setStep("leftToRight");
      } else if (step === "right") {
        addImage("right", data);
        setStep("rightToFront");
      } else if (step === "front") {
        addImage("front", data);
        setStep("done");
      }
    };
    reader.readAsDataURL(file);
  }

  return (
    <div className="top-image-wrapper">
      <div className="top-image" ref={topWebcamRef}>
        {showWebcamWrap && (
          <>
            <p className="progress">{step_num} / 3</p>
            <p className="top-text">or </p>
            <Upload
              accept="image/png,image/jpg"
              showUploadList={false}
              beforeUpload={(file) => beforeUpload(file, 3)}
              onChange={async (file) => {
                encodeImageFileAsURL(file.file);
                setWebcamRestart(false);
                // It's a trick. We wait 500ms to not show the video being
                // enabling before the success animation is shown.
                await new Promise((resolve) => setTimeout(resolve, 500));
                if (step === "front") return;
                webcamRef.current.video.srcObject
                  .getTracks()
                  .forEach((track) => (track.enabled = true));
              }}
            >
              <Button
                icon={<UploadOutlined />}
                onClick={() => {
                  setWebcamRestart(true);
                  webcamRef.current.video.srcObject
                    .getTracks()
                    .forEach((track) => (track.enabled = false));
                }}
              >
                Upload {step} profile
              </Button>
            </Upload>
          </>
        )}
      </div>
    </div>
  );
};

export default TopWebcam;
