import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import useStore from "../../store";
import { IoWarningOutline } from "react-icons/io5";
import { backendURL } from "../../utils/backend";
import ResultLoading from "./ResultLoading";
import "./Result.css";

const Result = ({ setMain }) => {
  const { images, setResult } = useStore();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const RetryButton = () => {
    return (
      <Button
        className="result-retry"
        size="large"
        onClick={() => setMain("landing")}
      >
        Retry
      </Button>
    );
  };

  useEffect(() => {
    (async () => {
      try {
        const body = JSON.stringify(Object.fromEntries(images));
        const res = await fetch(`${backendURL}/get_norwood_scale`, {
          method: "POST",
          mode: "cors",
          headers: { "Content-Type": "application/json" },
          body: body,
        });
        const data = await res.json();

        setResult(data.norwood, data.diffuse);
        if (data.norwood !== null && data.diffuse !== null) {
          navigate("/norwood-scale/result");
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);

  const Mode = () => {
    if (loading) {
      return <ResultLoading images={images} />;
    } else {
      return (
        <div className="result-error-server-wrapper">
          <div className="result-error-server">
            <IoWarningOutline size="4em" color="orange" />
            <p>
              Oops ! Our servers are suffering from success. Please try again in
              few minutes.
            </p>
            <RetryButton />
          </div>
        </div>
      );
    }
  };

  return <Mode />;
};

export default Result;
