import { useNavigate } from "react-router-dom";
import "./Footer.css";

function Footer() {
  const navigate = useNavigate();

  return (
    <footer>
      <p className="footer-text" onClick={() => navigate("/norwood-scale")}>
        What is the Norwood scale ?
      </p>
    </footer>
  );
}

export default Footer;
