import Member from "../components/about/Member";
import Header from "../components/shared/Header";
import mathieuCesbronSRC from "../images/mathieuCesbron.jpg";
import antoineMathurinSRC from "../images/antoineMathurin.jpg";
import follicle from "../images/follicle.jpeg";
import Footer from "../components/shared/Footer";
import FloatButton from "../components/shared/FloatButton";
import "./About.css";

const About = () => {
  const memberInfo = [
    {
      key: 1,
      imageSRC: mathieuCesbronSRC,
      imageAlt: "Mathieu Cesbron",
      name: "Mathieu Cesbron",
      job: "Hair Loss & Hair Disease Expert",
      norwood: 1.5,
      diffuse_thinner: false,
      location: "Manila, Philippines",
      education: "ICES - Catholic Institute of Higher Studies Master Degree",
    },
    {
      key: 2,
      imageSRC: antoineMathurinSRC,
      imageAlt: "Antoine Mathurin",
      name: "Antoine Mathurin",
      norwood: 0.5,
      diffuse_thinner: true,
      job: "Machine Learning Engineer",
      location: "Paris, France",
      education: "ECAM - Louis de Broglie Master Degree",
    },
  ];

  return (
    <div>
      <Header page={"/about"} />
      <div>
        <h2 className="about-members-title">Meet the Team</h2>
        <div className="about-members">
          {memberInfo.map((info) => (
            <Member info={info} key={info.key} />
          ))}
        </div>
        <h3 className="about-mission-title">Our Mission</h3>
        <div className="about-mission">
          <p className="about-mission-text">
            <img src={follicle} className="about-follicle" alt="follicle" />
            We want to revolutionize the way hair loss and hair diseases are
            diagnosed and treated. We bring together the expertise of a
            dedicated machine learning engineer and a seasoned hair disease and
            hair loss specialist to create a groundbreaking solution that
            combines medical knowledge with cutting-edge AI technology. <br />
            <br />
            <br />
            We believe that early detection is key to effective treatment. Our
            collaborative efforts empower us to develop an AI-powered platform
            that can analyze various hair-related conditions with unmatched
            accuracy. By leveraging machine learning algorithms, we can swiftly
            identify patterns, nuances, and potential problems that might go
            unnoticed by conventional methods.
          </p>
          <p></p>
        </div>
      </div>
      <FloatButton page="/about" />
      <Footer />
    </div>
  );
};

export default About;
