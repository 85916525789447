import { MdFaceRetouchingNatural } from "react-icons/md";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Menu, Tabs } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Header.css";

function Header({ setMain, page }) {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  const items = [
    {
      key: "/",
      label: "Home",
    },
    {
      key: "/get-checked",
      label: "Get Checked",
    },
    {
      key: "/norwood-scale",
      label: "Norwood Scale ?",
    },
    {
      key: "/diffuse-scale",
      label: "Diffuse Scale ?",
    },
    {
      key: "/about",
      label: "About Us",
    },
  ];

  if (setMain === undefined) {
    setMain = () => navigate("/");
  }

  return (
    <header>
      <div className="header-inner">
        <div className="title-full" onClick={() => setMain("landing")}>
          <div className="title-wrapper">
            <MdFaceRetouchingNatural className="amibalding-logo" />
            <h1 className="title">Am I balding ?</h1>
          </div>
          <p className="subtitle">Assess hair loss with AI</p>
        </div>
        <Button
          size="large"
          className="menu-btn"
          icon={
            showMenu ? (
              <CloseOutlined className="icon-btn" />
            ) : (
              <MenuOutlined className="icon-btn" />
            )
          }
          onClick={() => setShowMenu((val) => !val)}
        />
        <Tabs
          size="large"
          items={items.filter(
            (item) =>
              item.label !== "Norwood Scale ?" &&
              item.label !== "Diffuse Scale ?"
          )}
          className="menu-tabs"
          onTabClick={(key) => navigate(key)}
          defaultActiveKey={page}
        />
      </div>
      {showMenu && (
        <Menu
          defaultSelectedKeys={[page]}
          className="menu"
          items={items}
          onClick={({ key }) => navigate(key)}
          theme="dark"
        />
      )}
    </header>
  );
}

export default Header;
