import { Image } from "antd";
import Header from "../components/shared/Header";
import diffuseScaleIMG from "../images/diffuse-scale.jpg";
import diffuseScalePlaceholderIMG from "../images/diffuse-scale-placeholder.jpg";
import FloatButton from "../components/shared/FloatButton";
import Footer from "../components/shared/Footer";
import "./SharedScale.css";

const DiffuseScale = () => {
  return (
    <div>
      <Header page="/diffuse-scale" />
      <div className="space"></div>
      <div className="body-wrapper">
        <h2 className="scale-title">What is the Diffuse Scale ?</h2>
        <p className="scale-text">
          The Diffuse Scale or Diffuse thinning Scale is a classification system
          used to measure the extent of male and female pattern baldness. It is
          a scale from 0 to 3. 0 is no diffuse thinning at all while 3 is the
          strongest diffuse thinning.
        </p>
        <div className="scale-img-wrapper">
          <Image
            width={"100%"}
            src={diffuseScaleIMG}
            preview={false}
            placeholder={
              <Image
                width={"100%"}
                src={diffuseScalePlaceholderIMG}
                preview={false}
              />
            }
          />
        </div>
        <p className="scale-text">
          Other scales can be used like the Norwood Scale. The Diffuse Scale is
          often paired with the Norwood Scale for men to have a more complete
          assessment of hair loss.
        </p>
        <br />
        <br />
        <h2 className="scale-title">How is male pattern baldness diagnosed?</h2>
        <p className="scale-text">
          Hair loss can be diagnosed with a physical exam and medical history.
          Most hair loss is diagnosed as male pattern baldness, but if you're
          young, female, or experiencing unusual hair loss, then your doctor may
          want to rule out other possible causes.
          <br />
          <br />
          <a href="/get-checked">
            You can get your hair checked by hair loss experts for $19.
          </a>{" "}
          You will learn about the state of your hairloss, the evolution in the
          incoming years and which treatments are best suited for your case.
        </p>
        <br />
        <br />
        <h2 className="scale-title">How is hair loss treated?</h2>
        <p className="scale-text">
          Finasteride and Minoxidil are the top 2 treatments used and often do
          50%+ of the job of regrowth. Plenty of other treatments exist, you can
          find the list{" "}
          <a
            href="https://cdn.discordapp.com/attachments/1102313146888233032/1143559623593439262/hair-loss-treatments-rated-by-gpt-4-v0-uh1oipwiggqa1.png"
            target="_blank"
          >
            here
          </a>{" "}
          rated by efficacy and side effects.
        </p>
        <p className="scale-text">
          Hair loss treatments are most successful when started early. It's
          easier to slow down hair loss than it is to stimulate new hair growth.
          Hair follicles that stop producing hair become dormant after about two
          years and can't be reactivated. Once significant hair loss has
          occurred, surgical procedures may be the best option.
        </p>
        <br />
      </div>
      <FloatButton />
      <Footer />
    </div>
  );
};

export default DiffuseScale;
