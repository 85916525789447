import { create } from "zustand";

const useStore = create((set) => ({
  humanLib: null,
  setHumanLib: (value) => set({ humanLib: value }),
  human: null,
  setHuman: (value) => set({ human: value }),
  images: new Map(),
  addImage: (key, value) => {
    set((state) => {
      const updatedImages = new Map(state.images);
      updatedImages.set(key, value);
      return { images: updatedImages };
    });
  },
  result: null,
  setResult: (norwood, diffuse) =>
    set({ result: { norwood: norwood, diffuse: diffuse } }),
}));

export default useStore;
