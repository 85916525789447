import { Image } from "antd";
import { RiArrowRightUpLine } from "react-icons/ri";
import faceExperts from "../images/faceExperts.png";
import faceExpertsPlaceholder from "../images/faceExpertsPlaceholder.png";
import Header from "../components/shared/Header";
import Service from "../components/getChecked/Service";
import HairCheckForm from "../components/getChecked/HairCheckForm";
import Footer from "../components/shared/Footer";
import { useEffect, useRef } from "react";
import "./GetChecked.css";

const GetChecked = () => {
  const endRef = useRef(null);

  const services = [
    {
      key: 1,
      text: "Evolution of your hairloss and Norwood Scale in the next years",
    },
    { key: 2, text: "Your Norwood Scale from 0 to 7" },
    { key: 3, text: "Your Diffuse Thinning Scale from 0 to 3" },
    {
      key: 4,
      text: "If you have a mature hairline or will continue to recede.",
    },
    {
      key: 5,
      text: "If you have hair disease like Psoriasis and Folliculitis",
    },
    {
      key: 6,
      text: "If you are or will be a good candidate for hair transplant",
    },
    { key: 7, text: "The best time to do a hair transplant for you" },
    { key: 8, text: "Customised treatments for optimal results" },
    {
      key: 9,
      text: "Alternative treatments for people who cannot tolerate Finasteride/Dutasteride",
    },
    {
      key: 10,
      text: "Hair check garanteed by email in less than 24 hours",
    },
  ];

  useEffect(() => {
    if (window.location.hash === "#bottom")
      endRef.current?.scrollIntoView({ behavior: "smooth" });
  });

  return (
    <div>
      <Header page="/get-checked" />
      <div className="get-checked-wrapper">
        <div className="get-checked-title">
          <p className="get-checked-start-title">Get a hair check from </p>
          <h1 className="get-checked-end-title"> Hair Loss Experts</h1>
        </div>
        <p className="get-checked-subtitle">
          Securely upload your hair loss pictures and symptoms below. An expert
          will respond with the <b>state of your hair loss</b>, the{" "}
          <b>predicted evolution over years</b>, and <b>which treatments</b> are
          best suited for your case.
        </p>
        <div className="get-checked-face-image-wrapper">
          <Image
            src={faceExperts}
            className="get-checked-face-image"
            preview={false}
            alt="faces hair loss expert"
            placeholder={
              <Image
                src={faceExpertsPlaceholder}
                className="get-checked-face-image"
                preview={false}
                alt="faces hair loss expert placeholder"
              />
            }
          />
        </div>
        <a className="learn-about-us-wrapper" href="/about" target="_blank">
          <p>Learn about us</p>
          <RiArrowRightUpLine className="learn-about-us-arrow" />
        </a>
        <p className="get-checked-price-wrapper">
          Simple, effective for <span className="get-checked-price">$19</span>
        </p>
        <div className="get-checked-services-wrapper">
          <p className="get-checked-services-title">What you will get</p>
          <div className="get-checked-services">
            {services.map((info) => (
              <Service info={info} key={info.key} />
            ))}
          </div>
        </div>
        <a
          className="example-wrapper"
          href="https://drive.google.com/file/d/1fojJX1yFPweK_yg3Vnr1Sfdbd1_C_jiB/view"
          target="_blank"
        >
          <p>Example of Hair Check</p>
          <RiArrowRightUpLine className="example-arrow" />
        </a>
        <HairCheckForm />
      </div>
      <Footer backgroundColor={"rgba(128, 128, 128, 0.761)"} />
      <div ref={endRef}></div>
    </div>
  );
};

export default GetChecked;
