import { Image } from "antd";
import facePlaceholder from "../../images/facePlaceholder.webp";
import "./Member.css";

const Member = ({ info }) => {
  return (
    <div className="member-wrapper">
      <div className="member-image-wrapper">
        <Image
          src={info.imageSRC}
          className="member-image"
          alt={info.imageAlt}
          preview={false}
          style={{ borderRadius: "50%" }}
          placeholder={
            <Image
              src={facePlaceholder}
              className="member-image"
              alt="face placeholder"
              preview={false}
              style={{ borderRadius: "50%" }}
            />
          }
        />
      </div>
      <div className="member-text">
        <p className="member-name">{info.name}</p>
        <h3>{info.job}</h3>
        <p>
          <span style={{ color: "#1677ff" }}>Norwood {info.norwood}</span>{" "}
          {info.diffuse_thinner && "+ Diffuse thinner (DUPA)"}
        </p>
        <p>{info.location}</p>
        <p>{info.education}</p>
      </div>
    </div>
  );
};

export default Member;
