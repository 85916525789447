import { useState, useEffect, useRef } from "react";
import Prompt from "./Prompt";
import Denied from "./Denied";
import Granted from "./Granted";
import Result from "./Result";
import "./Camera.css";

const Camera = ({ modelLoaded, setMain }) => {
  const webcamRef = useRef(null);
  const [permission, setPermission] = useState("");
  const [showResult, setShowResult] = useState(false);

  useEffect(() => {
    const checkPermissions = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "camera",
        });
        setPermission(permissionStatus.state);
      } catch (error) {
        console.error("Error checking camera permission:", error);
      }
    };
    checkPermissions();

    const enableCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (webcamRef.current) {
          webcamRef.current.srcObject = stream;
        }
        setPermission("granted");
      } catch (error) {
        if (error.name === "NotAllowedError") {
          console.log("User denied camera access.");
          setPermission("denied");
        } else {
          console.error("Error accessing camera:", error);
        }
      }
    };

    if (permission !== "granted") {
      enableCamera();
    }
  }, []);

  const Mode = () => {
    if (showResult) {
      return <Result setMain={setMain} />;
    } else if (permission === "prompt") {
      return <Prompt />;
    } else if (permission === "denied") {
      return <Denied />;
    } else if (permission === "granted") {
      return (
        <Granted modelLoaded={modelLoaded} setShowResult={setShowResult} />
      );
    }
  };

  return (
    <div className="main-content">
      <Mode />
    </div>
  );
};

export default Camera;
