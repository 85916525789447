import { message, Upload } from "antd";

export const humanConfig = {
  modelBasePath: "models/",
  debug: true,
  filter: {
    width: 500,
    height: 500,
  },
  face: {
    enabled: true,
    detector: { rotation: true },
    description: { enabled: false },
    iris: { enabled: false },
    emotion: { enabled: false },
    antispoof: { enabled: false },
    liveness: { enabled: false },
  },
  hand: {
    enabled: false,
  },
  body: {
    enabled: false,
  },
  object: {
    enabled: false,
  },
  gesture: {
    enabled: false,
  },
};

export const norwoodText = (norwoodScale) => {
  if (norwoodScale < 1) {
    return "You have a great hairline. Nothing to worry about yet, let's hope it stays like this !";
  } else if (norwoodScale < 2) {
    return "You have a good hairline. It is a good time to start taking care of it. It's easier to slow down hair loss than it is to stimulate new hair growth.";
  } else if (norwoodScale < 3) {
    return "Your hairline has started to recede. We advise you to pick an haircut that suit your face and Norwood. It is a good time to start taking care of it.";
  } else if (norwoodScale < 4) {
    return "You are definitely balding. We advise you to pick an haircut that suit your face and hairline. You can think about hair transplant at this stage.";
  } else if (norwoodScale < 5) {
    return "You are strongly balding. You can think about hair transplant at this stage. You will also have to use hair loss treatments to recover your hairline.";
  } else {
    return "You are strongly balding. You could think about hair transplant or hair system. You will also have to use hair loss treatments to recover your hairline.";
  }
};

export const diffuseText = (diffuseScale) => {
  if (diffuseScale === 0) {
    return "You are not a diffuse thinner. Nothing to worry about it. The best way to not start thinning is to eat a nutritious and non-inflammatory diet.";
  } else {
    return "You are a diffuse thinner. Consider eating a nutritious and non-inflammatory diet if that's not already the case.";
  }
};

export const beforeUpload = (file, MBLimit) => {
  const isValidSize = file.size / 1024 / 1024 < MBLimit;
  if (!isValidSize) {
    message.error(`Image must be smaller than ${MBLimit}MB`);
    return Upload.LIST_IGNORE;
  }
  return false;
};
