import ReactWebcam from "react-webcam";
import { useEffect, useRef, useState } from "react";
import { runDetector } from "../../utils/detector";
import useStore from "../../store";
import { Button } from "antd";
import "./Webcam.css";

const Webcam = ({
  webcamRef,
  setShowResult,
  webcamLoaded,
  setWebcamLoaded,
  step,
  isIntermediateStep,
  setStep,
  imageSRC,
  topWebcamRef,
  setWebcamWrapResized,
  webcamRestart,
  setWebcamRestart,
}) => {
  const { addImage, human } = useStore();
  const [variablesTest, setVariablesTest] = useState({});

  const canvasRef = useRef(null);

  const handleVideoLoad = (videoNode) => {
    const webcamElement = webcamRef.current.video;
    const width = Math.min(
      webcamElement.offsetWidth,
      webcamElement.offsetHeight
    );
    const height = Math.min(
      webcamElement.offsetWidth,
      webcamElement.offsetHeight
    );

    human.config.filter.width = width;
    human.config.filter.height = height;

    const video = videoNode.target;
    video.width = width;
    video.height = height;

    canvasRef.current.width = width;
    canvasRef.current.height = height;

    if (topWebcamRef.current) {
      topWebcamRef.current.style.display = "flex";
      topWebcamRef.current.style.maxWidth = `${width}px`;
      setWebcamWrapResized(true);
    }

    // Mirror the canvas
    const ctx = canvasRef.current.getContext("2d");
    ctx.translate(width, 0);
    ctx.scale(-1, 1);

    if (video.readyState !== 4) return;
    if (webcamLoaded) return;
    setWebcamLoaded(true);
  };

  useEffect(() => {
    if (!webcamLoaded) return;

    if (step === "leftToRight") {
      setTimeout(() => {
        setStep("right");
      }, 3000);
    } else if (step === "rightToFront") {
      setTimeout(() => {
        setStep("front");
      }, 3000);
    } else if (step === "done") {
      setShowResult(true);
    }

    const stopDetector = runDetector(
      webcamRef.current,
      canvasRef,
      human,
      step,
      setStep,
      addImage,
      setVariablesTest
    );

    return () => {
      stopDetector.then((stop) => {
        stop();
      });
    };
  }, [webcamLoaded, step]);

  return (
    <div className="webcam-wrapper">
      <ReactWebcam
        playsInline
        ref={webcamRef}
        mirrored={true}
        className="webcam"
        onLoadedData={handleVideoLoad}
        videoConstraints={{
          facingMode: "user",
          aspectRatio: 1,
          width: canvasRef.current?.width,
          height: canvasRef.current?.height,
        }}
        style={{
          visibility:
            isIntermediateStep || webcamRestart ? "hidden" : "visible",
        }}
      />
      <canvas ref={canvasRef} className="webcam-canvas"></canvas>
      {isIntermediateStep && (
        <img src={imageSRC} alt="success" className="webcam-overlay" />
      )}
      {!isIntermediateStep && webcamLoaded && !webcamRestart && (
        <img src={imageSRC} alt="silhouette" className="webcam-overlay" />
      )}
      {webcamRestart && (
        <div
          className="webcam-restart"
          style={{
            width: canvasRef.current?.width,
            height: canvasRef.current?.height,
          }}
        >
          <Button
            size="large"
            className="webcam-restart-btn"
            onClick={() => {
              webcamRef.current.video.srcObject
                .getTracks()
                .forEach((track) => (track.enabled = true));
              setWebcamRestart(false);
            }}
          >
            Restart Camera
          </Button>
        </div>
      )}
      {/* For Debug only */}
      {/* <p
        style={{
          position: "absolute",
          top: 0,
          color: "white",
          border: "pink solid 5px",
        }}
      >
        pitch:{variablesTest.pitch} roll:{variablesTest.roll} yaw:
        {variablesTest.yaw} size:
        {variablesTest.size}
      </p> */}
    </div>
  );
};

export default Webcam;
