import { Image } from "antd";
import Header from "../components/shared/Header";
import norwoodScaleIMG from "../images/norwood-scale.svg";
import norwoodScalePlaceholderIMG from "../images/norwood-scale-placeholder.jpg";
import Footer from "../components/shared/Footer";
import FloatButton from "../components/shared/FloatButton";
import "./SharedScale.css";

const NorwoodScale = () => {
  return (
    <div>
      <Header page="/norwood-scale" />
      <div className="space"></div>
      <div className="body-wrapper">
        <h2
          className="
        scale-title"
        >
          What is the Norwood Scale?
        </h2>
        <p className="scale-text">
          The Norwood Scale is the leading classification system used to measure
          the extent of male pattern baldness. It is a scale from 0 to 7. 0 is a
          perfect hairline while 7 is fully bald.
        </p>
        <div className="scale-img-wrapper">
          <Image
            width={"100%"}
            src={norwoodScaleIMG}
            preview={false}
            placeholder={
              <Image
                width={"100%"}
                src={norwoodScalePlaceholderIMG}
                preview={false}
              />
            }
          />
        </div>
        <p className="scale-text">
          Other scales can be used like the Ludwig Scale or the Savin Scale but
          the Norwood Scale is by far the most used by experts.
        </p>
        <br />
        <br />
        <h2 className="scale-title">How is male pattern baldness diagnosed?</h2>
        <p className="scale-text">
          Hair loss can be diagnosed with a physical exam and medical history.
          Most hair loss is diagnosed as male pattern baldness, but if you're
          young, female, or experiencing unusual hair loss, then your doctor may
          want to rule out other possible causes.
          <br />
          <br />
          <a href="/get-checked">
            You can get your hair checked by hair loss experts for $19.
          </a>{" "}
          You will learn about the state of your hairloss, the evolution in the
          incoming years and which treatments are best suited for your case.
        </p>
        <br />
        <br />
        <h2 className="scale-title">How is hair loss treated?</h2>
        <p className="scale-text">
          Finasteride and Minoxidil are the top 2 treatments used and often do
          50%+ of the job of regrowth. Plenty of other treatments exist, you can
          find the list{" "}
          <a
            href="https://cdn.discordapp.com/attachments/1102313146888233032/1143559623593439262/hair-loss-treatments-rated-by-gpt-4-v0-uh1oipwiggqa1.png"
            target="_blank"
          >
            here
          </a>{" "}
          rated by efficacy and side effects.
        </p>
        <p className="scale-text">
          Hair loss treatments are most successful when started early. It's
          easier to slow down hair loss than it is to stimulate new hair growth.
          Hair follicles that stop producing hair become dormant after about two
          years and can't be reactivated. Once significant hair loss has
          occurred, surgical procedures may be the best option.
        </p>
        <br />
      </div>
      <FloatButton />
      <Footer />
    </div>
  );
};

export default NorwoodScale;
