import { BiCheck } from "react-icons/bi";
import "./Service.css";

const Service = ({ info }) => {
  return (
    <div className="service">
      <BiCheck className="service-check" />
      <p>{info.text}</p>
    </div>
  );
};

export default Service;
