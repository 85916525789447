import {
  Form,
  Input,
  Select,
  Button,
  Radio,
  Checkbox,
  Spin,
  InputNumber,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import UploadImage from "./UploadImage";
import countriesOptions from "../../utils/countries";
import treatmentsOptionsDefault from "../../utils/treatments";
import { backendURL } from "../../utils/backend";
import "./HairCheckForm.css";
import { useState } from "react";

const HairCheckForm = () => {
  const [checked, setChecked] = useState(false);
  const [submitBtn, setSubmitBtn] = useState("submit");

  const [treatments, setTreatments] = useState([]);
  const [treatmentsOptions, setTreatmentsOptions] = useState(
    treatmentsOptionsDefault
  );

  const onFinish = async (e) => {
    setSubmitBtn("loading");
    console.log("Form values:", e);
    delete e.checkbox;

    try {
      const res = await fetch(`${backendURL}/hair-check`, {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(e),
      });

      if (!res.ok) {
        setSubmitBtn("error");
        return;
      }

      const data = await res.json();
      console.log(data);
      window.location.href = data.url;
    } catch (error) {
      setSubmitBtn("error");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Form submission failed:", errorInfo);
  };

  const Submit = () => {
    // eslint-disable-next-line
    switch (submitBtn) {
      case "submit":
        return <p>Submit & Pay</p>;
      case "loading":
        return (
          <Spin
            indicator={<LoadingOutlined />}
            className="spin"
            style={{ color: "white" }}
          />
        );
      case "error":
        return <p>Oops! Click to retry</p>;
    }
  };

  return (
    <Form
      name="image-upload-form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <div className="form-item-title-wrapper">
        <span className="form-number">1</span>
        <h4 className="form-item-title">Upload hairline pictures</h4>
      </div>
      <div className="images-profile-upload">
        <UploadImage text="Upload Left Profile" name="leftImage" />
        <UploadImage text="Upload Front Profile" name="frontImage" />
        <UploadImage text="Upload Right Profile" name="rightImage" />
      </div>
      <div className="form-item-title-wrapper">
        <span className="form-number">2</span>
        <h4 className="form-item-title">Personal informations</h4>
      </div>
      <Form.Item
        name="sex"
        rules={[{ required: true, message: "Please pick a value" }]}
      >
        <Radio.Group>
          <Radio value="Female">Female</Radio>
          <Radio value="Male">Male</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="age"
        rules={[
          {
            required: true,
            message: "Please enter your age",
            type: "number",
            min: 0,
            max: 99,
          },
        ]}
      >
        <InputNumber placeholder="Age" min={0} max={99} />
      </Form.Item>
      <Form.Item
        name="city"
        rules={[{ required: true, message: "Please enter your city" }]}
      >
        <Input placeholder="City" />
      </Form.Item>
      <Form.Item
        name="country"
        rules={[{ required: true, message: "Please pick your country" }]}
      >
        <Select showSearch placeholder="Country" options={countriesOptions} />
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Please enter a valid email",
            type: "email",
          },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>
      <div className="form-item-title-wrapper">
        <span className="form-number">3</span>
        <h4 className="form-item-title">Details</h4>
      </div>
      <Form.Item
        name="treatments"
        rules={[
          { required: true, message: "Please pick your current treatments" },
        ]}
      >
        <Select
          placeholder="Current treatments"
          value={treatments}
          mode="multiple"
          options={treatmentsOptions}
          onChange={(treatmentList) => {
            setTreatments(treatmentList);
            if (treatmentList.includes("None")) {
              setTreatmentsOptions((prevTreatmentsOptions) =>
                prevTreatmentsOptions.map((t) => ({
                  ...t,
                  disabled: t.value !== "None",
                }))
              );
            } else {
              setTreatmentsOptions((prevTreatmentsOptions) =>
                prevTreatmentsOptions.map((t) => ({
                  ...t,
                  disabled: false,
                }))
              );
            }
          }}
        ></Select>
      </Form.Item>
      <Form.Item name="details">
        <Input.TextArea
          maxLength={10000}
          placeholder="Includes how the condition has evolved, your reactions to treatments, etc. More information usually leads to better responses."
          style={{ height: "100px" }}
        />
      </Form.Item>
      <Form.Item
        name="checkbox"
        valuePropName="checked"
        rules={[
          {
            validator: async (_, checked) => {
              if (!checked) {
                return Promise.reject(new Error("Please check box"));
              }
            },
          },
        ]}
      >
        <Checkbox
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        >
          I understand that the Hair Check result will be sent by email in less
          than 24 hours.
        </Checkbox>
      </Form.Item>
      <div className="form-btn-submit-wrapper">
        <Button
          disabled={!checked}
          className="form-btn-submit"
          type="primary"
          htmlType="submit"
          size="large"
        >
          <Submit />
        </Button>
      </div>
    </Form>
  );
};

export default HairCheckForm;
