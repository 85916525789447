import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/shared/Header";
import useStore from "../store";
import { norwoodText, diffuseText } from "../utils/config";
import { Button, Card } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Footer from "../components/home/Footer";
import FloatButton from "../components/shared/FloatButton";
import diffuseIMG from "../images/diffuse.png";
import norwoodScaleUnit1 from "../images/norwood_1.png";
import norwoodScaleUnit2 from "../images/norwood_2.png";
import norwoodScaleUnit3 from "../images/norwood_3.png";
import norwoodScaleUnit4 from "../images/norwood_4.png";
import norwoodScaleUnit5 from "../images/norwood_5.png";
import norwoodScaleUnit6 from "../images/norwood_6.png";
import norwoodScaleUnit7 from "../images/norwood_7.png";
import "./Result.css";

const Result = () => {
  const { result } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (result === null) {
      navigate("/");
    }
  }, [result, navigate]);

  if (result === null) {
    return null;
  }

  const RetryButton = () => {
    return (
      <Button
        type="primary"
        className="result-retry"
        size="large"
        onClick={() => navigate("/")}
      >
        Retry
      </Button>
    );
  };

  var imageSRC;
  if (result.norwood <= 1) {
    imageSRC = norwoodScaleUnit1;
  } else if (result.norwood <= 2) {
    imageSRC = norwoodScaleUnit2;
  } else if (result.norwood <= 3) {
    imageSRC = norwoodScaleUnit3;
  } else if (result.norwood <= 4) {
    imageSRC = norwoodScaleUnit4;
  } else if (result.norwood <= 5) {
    imageSRC = norwoodScaleUnit5;
  } else if (result.norwood <= 7) {
    imageSRC = norwoodScaleUnit6;
  } else {
    imageSRC = norwoodScaleUnit7;
  }

  return (
    <div>
      <Header />
      <div className="result">
        <Card
          className="result-card"
          title={
            <div className="result-card-title-wrapper">
              <h2>Norwood scale</h2>
              <QuestionCircleOutlined
                className="result-card-title-question"
                onClick={() => window.open("/norwood-scale", "_blank")}
              />
            </div>
          }
        >
          <div className="result-scale-content">
            <p>
              <span className="result-scale">{result.norwood}</span>{" "}
              <span className="result-scale-ratio">/ 7</span>
            </p>
            <img
              src={imageSRC}
              height="100%"
              alt="norwood scale unit"
              className="result-scale-img"
            />
          </div>
          <p>{norwoodText(result.norwood)}</p>
        </Card>

        <Card
          className="result-card"
          title={
            <div className="result-card-title-wrapper">
              <h2>Diffuse scale</h2>
              <QuestionCircleOutlined
                className="result-card-title-question"
                onClick={() => window.open("/diffuse-scale", "_blank")}
              />
            </div>
          }
        >
          <div className="result-scale-content">
            <p>
              <span className="result-scale">{result.diffuse}</span>{" "}
              <span className="result-scale-ratio">/ 3</span>
            </p>
            <img
              src={diffuseIMG}
              className="result-scale-img"
              alt="diffuse scale unit"
              height="100%"
            />
          </div>
          <p>{diffuseText(result.diffuse)}</p>
        </Card>

        <Card className="result-card" title="Hair loss treatments for you">
          <p>
            We recommand you to use{" "}
            <a href="https://findhairlossproducts.com/" target="_blank">
              Minoxidil 5% topical
            </a>{" "}
            daily, it is the best for regrowing hair follicles. You can move to
            10% if you tolerate it well.
          </p>
          <br />
          <p>
            You can also use{" "}
            <a href="https://findhairlossproducts.com/" target="_blank">
              Finasteride
            </a>
            . It has more side effects but works better than Minoxidil in the
            long term. We recommand using the topical version and applying 1ml
            every day.
          </p>
          <br />
          <p>
            You might consider{" "}
            <a href="/get-checked">getting your hair checked</a> to know the
            evolution of your hair loss and take appropriate actions.
          </p>
        </Card>
        <RetryButton />
      </div>
      <Footer />
      <FloatButton />
    </div>
  );
};

export default Result;
