import { Spin, Upload } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./ResultLoading.css";

const ResultLoading = ({ images }) => {
  const fileList = [
    {
      uid: "0",
      name: "left",
      url: images.get("left"),
    },
    {
      uid: "1",
      name: "right",
      url: images.get("right"),
    },
    {
      uid: "2",
      name: "front",
      url: images.get("front"),
    },
  ];

  return (
    <div className="result-loading">
      <Spin size="large" indicator={<LoadingOutlined />} />
      <p>Assessing your hair loss with AI. It can take up to 30 seconds.</p>
      <Upload
        listType="picture-card"
        fileList={fileList}
        showUploadList={{ showPreviewIcon: false, showRemoveIcon: false }}
      />
    </div>
  );
};

export default ResultLoading;
