import { IoWarningOutline } from "react-icons/io5";
import "./Denied.css";

const Denied = () => {
  return (
    <div className="denied-access-wrapper">
      <IoWarningOutline size="4em" className="warning-denied-access" />
      <p className="denied-text">You denied access to the camera.</p>
      <p className="denied-text">
        Allow camera access in your browser settings to know your Norwood scale.
      </p>
    </div>
  );
};

export default Denied;
