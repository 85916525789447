import { AiFillVideoCamera } from "react-icons/ai";
import { Button } from "antd";
import "./MainLanding.css";

function MainLanding({ setMain }) {
  const onClickHandler = async () => {
    setMain("camera");

    // Trick on Safari only to ask the user for access.
    // It is necessary because Safari wants the user to click before getting
    // prompt and a useEffect will be refused.
    const isSafari = window.safari !== undefined;
    if (isSafari) {
      try {
        await navigator.mediaDevices.getUserMedia({
          video: true,
        });
      } catch (error) {
        console.log("Error Safari asking for camera access: ", error);
      }
    }
  };

  return (
    <main className="main-landing">
      <p className="main-text">
        Press the button to access your camera and find your{" "}
        <a style={{ color: "#d37c54" }} href="/norwood-scale" target="_blank">
          Norwood scale
        </a>{" "}
        and your{" "}
        <a style={{ color: "#d37c54" }} href="/diffuse-scale" target="_blank">
          Diffuse scale
        </a>
      </p>
      <Button
        icon={<AiFillVideoCamera />}
        type="primary"
        className="main-btn"
        onClick={onClickHandler}
      >
        Use my camera
      </Button>
      <p className="below-text">
        Want to know the evolution of your hair loss ?<br /> Get your{" "}
        <a href="/get-checked">hair checked by experts</a> for $19.
      </p>
      <p className="below-text" style={{ color: "gray" }}>
        Photos are not stored online, privacy is important.
      </p>
    </main>
  );
}

export default MainLanding;
