import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import "./FloatButton.css";

const FloatButton = ({ page }) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        position: "fixed",
        bottom: page === "/" ? "70px" : "20px",
        right: page === "/" ? "20px" : "20px",
        zIndex: 1000,
      }}
    >
      <Button
        type="primary"
        size="large"
        onClick={() => {
          window.scrollTo({ top: 0 });
          navigate("/get-checked");
        }}
        className="float-button"
        shape="round"
      >
        <p className="float-button-text">Get Checked</p>
      </Button>
    </div>
  );
};

export default FloatButton;
