import { useState, useEffect } from "react";
import Footer from "../components/home/Footer";
import Header from "../components/shared/Header";
import MainLanding from "../components/home/MainLanding";
import Camera from "../components/home/Camera";
import useStore from "../store";
import { humanConfig } from "../utils/config";
import FloatButton from "../components/shared/FloatButton";
import "./Home.css";

function Home() {
  const [main, setMain] = useState("landing");
  const [modelLoaded, setModelLoaded] = useState(false);
  const setHumanLib = useStore((state) => state.setHumanLib);
  const setHuman = useStore((state) => state.setHuman);

  // Load face detection models since the start of app
  useEffect(() => {
    (async () => {
      const humanLib = await import("@vladmandic/human");
      setHumanLib(humanLib);

      const human = new humanLib.Human(humanConfig);
      await human.load();
      setHuman(human);
      setModelLoaded(true);
    })();
  }, []);

  return (
    <>
      <div className="app">
        <Header setMain={setMain} page={"/"} />
        {main === "landing" && <MainLanding setMain={setMain} />}
        {main === "camera" && (
          <Camera modelLoaded={modelLoaded} setMain={setMain} />
        )}
        {main === "landing" && <FloatButton page="/" />}
        <Footer />
      </div>
      <h1 className="rotate-message">
        Rotate screen to portrait mode to use the application
      </h1>
    </>
  );
}

export default Home;
