import { Form, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import "./UploadImage.css";

const UploadImage = ({ text, name }) => {
  const [image, setImage] = useState([]);

  const handleImageChange = (info) => {
    setImage(info.fileList);
  };

  const getValue = (e) => {
    console.log("uploading event", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <>
      <Form.Item
        name={name}
        getValueFromEvent={getValue}
        rules={[
          {
            required: true,
            message: "Please Upload image",
          },
        ]}
      >
        <Upload
          listType="picture-card"
          beforeUpload={() => false}
          onChange={handleImageChange}
          showUploadList={{ showPreviewIcon: false }}
          itemRender={(originNode) => (
            <div
              {...originNode.props}
              style={{
                width: 200,
                height: 200,
              }}
            />
          )}
        >
          {image.length >= 1 ? null : (
            <div>
              <PlusOutlined />
              <p>{text}</p>
            </div>
          )}
        </Upload>
      </Form.Item>
    </>
  );
};

export default UploadImage;
